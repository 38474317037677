<template>
    <div>

        <b-form >
            <b-row>
                 <b-col
                    cols="12"
                    class="mb-1"
                >
                    <h5 class="mb-0">
                        Módulos del Curso
                    </h5>
                    <small class="text-muted">
                        Actualice los detalles del módulo
                    </small>
                    <hr>
                </b-col>
                
                <!-- button modal module-->
                <b-col
                    cols="12"
                    class="mb-2"
                >                                     
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"                        
                        v-b-modal.modal-login
                        @click="openModal"
                    >
                        <feather-icon
                            class="cursor-pointer"
                            icon="PlusIcon"
                        />
                        Crear Módulo
                    </b-button>
                
                </b-col>                
                
                <!-- modal add module -->
                <b-modal
                    ref="modal"
                    id="modal-login"
                    v-if="modalShow"
                    content-class="shadow"
                    cancel-variant="outline-secondary"
                    cancel-title="Cancelar"
                    centered
                    title="Agregar Nuevo Módulo"
                >
                    <b-form>
                        <b-form-group>
                            <label for="newname">Nombre</label>
                            <b-form-input
                                id="newname"
                                type="text"
                                v-model="newname"
                                placeholder="Ingrese el nombre del módulo"
                            />
                        </b-form-group>
                    </b-form>
                    <template #modal-footer>
                        <b-button
                            variant="primary"
                            @click="addModule"
                            class="float-right">
                                Agregar
                        </b-button>
                    </template>
                </b-modal>
                
                
                <!-- Módulos -->
                <b-col 
                    cols="12"
                    v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
                    class="moduleStyle"
                >  
                    <div class="repeater-form mt-2" > 
                        <b-row>
                            <template v-if="modules.length == 0">
                                <b-col sm="12">
                                    <b-alert
                                        variant="primary"
                                        show
                                    >
                                        <h4 class="alert-heading">
                                            Info
                                        </h4>
                                        <div class="alert-body">
                                            <span>¡Este curso no tiene módulos! Click en el botón crear para añadir un nuevo módulo.</span>
                                        </div>
                                    </b-alert>
                                </b-col>
                            </template>
                            <template v-else>
                                <b-col md="6"
                                    v-for="(item, index) in modules"
                                    :id="item.id"
                                    :key="index"                             
                                >
                                    <b-card-actions                                    
                                        :title= "`Módulo ${index + 1}`"
                                        action-collapse     
                                        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" 
                                    >
                                        <b-row>
                                            <!-- Nombre del Módulo -->
                                            <b-col md="9">
                                                <b-form-group
                                                    label="Nombre"
                                                    label-for="module"											
                                                >
                                                    <b-form-input
                                                        v-model="item.name"
                                                        type="text"   
                                                        trim                                     
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- <b-col md="2" class="text-center">
                                                <b-form-group                            
                                                    label-for="certificate"
                                                    label="Certificado"
                                                >                       
                                                    <b-form-checkbox              
                                                        v-model="item.certificate"                                                                             
                                                        class="custom-control-primary mt-1"
                                                        name="certificado"
                                                        @click="toggle"
                                                        switch
                                                    >
                                                        <span class="switch-icon-left">
                                                            <feather-icon icon="CheckIcon" />
                                                        </span>
                                                        <span class="switch-icon-right">
                                                            <feather-icon icon="XIcon" />
                                                        </span>
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col> -->
                                            
                                            <!-- Folders Button -->
                                            <b-col
                                                md="1"
                                                class="mb-50 px-0"
                                            >
                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    v-b-tooltip.hover.v-primary
                                                    variant="outline-primary"
                                                    class="mt-0 mt-md-2 px-1"                                                 
                                                    title="Gestionar Tabs"
                                                    :to="{ name: 'modules-edit', params: { id: courseId, moduleId: item.id }}"
                                                >
                                                    <feather-icon
                                                        icon="FolderIcon"                             
                                                    />
                                                </b-button>
                                            </b-col>

                                            <!-- Save Button -->
                                            <b-col
                                                md="1"
                                                class="mb-50 px-0"
                                            >
                                                <b-button
                                                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                                    v-b-tooltip.hover.v-warning
                                                    variant="outline-warning"
                                                    class="mt-0 mt-md-2 px-1"                                                
                                                    title="Actualizar"
                                                    @click="updateInformation(item.id)"
                                                >
                                                    <feather-icon
                                                        icon="SaveIcon"                             
                                                    />
                                                </b-button>
                                            </b-col>
                                            
                                            <!-- Remove Button -->
                                            <b-col
                                                md="1"
                                                class="mb-50 px-0"
                                            >
                                                <b-button
                                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                    v-b-tooltip.hover.v-danger
                                                    variant="outline-danger"
                                                    class="mt-0 mt-md-2 px-1"
                                                    title="Eliminar"
                                                    @click="removeModule(index, item.id)"
                                                >
                                                    <feather-icon
                                                        icon="TrashIcon"                             
                                                    />
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-card-actions>
                                </b-col>
                            </template>                            
                        </b-row>                       
                    </div> 
                </b-col>	
            </b-row>            
        </b-form>    
    </div>
</template>

<script>
    require('@core/assets/fonts/feather/iconfont.css')
    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
    import { BAlert, VBTooltip, BModal, VBModal, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormCheckbox,
			BCardText,} from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import Ripple from 'vue-ripple-directive'
    import { ref } from '@vue/composition-api';
    import useAppConfig from '@core/app-config/useAppConfig';
    import { watch } from '@vue/composition-api';
    import axios from 'axios';

export default {
        components: {
            BAlert,
            BButton,
            BModal, 
            VBModal,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BForm,
            BFormCheckbox,
			BCardText,
            BCardActions,
            vSelect,
        },
        directives: {
            'b-modal': VBModal,
            'b-tooltip': VBTooltip,
		    Ripple,
	    },
        props: {
            userData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                courseId: 0,
                modal: {},
                isActive: false,
                modulesOld: [],
                newname: null,
                modalShow: true,  
                
                // For modules
                deletedModule: [],
                nextTodoId: 1,
                modules: [],
            }
        }, 
        mounted (){            
            this.courseId = this.userData.id
            this.modal = ref()
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.courseId}/module/list`)
            .then(response => {			
                this.modulesOld = response.data;
            });
            
            setTimeout(() => {
                this.modulesList(this.modulesOld);
            }, 500);
            
        },
        methods:{
            // toggle(){
            //     this.isActive = !this.enable;
            // },
            modulesList (modulos){
                this.modules = [];		  
                modulos.map(item => {
                    this.modules.push({
                        id: item.id,
                        name: item.name,
                        exists: true,
                    });
                });     
            },
            openModal(){
                this.modalShow = true;
            },
            addModule (){
                if (this.newname){                    
                    axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.courseId}/module/create`, { name : this.newname})
                    .then(response => {                         
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });                                                                                 
                        this.modalShow = false;
                        this.modules.push({
                            id: response.data.moduleId,
                            name: this.newname,
                            exists: false,
                        });
                        this.modulesList (this.modules)
                        this.nextTodoId += 1;
                        this.newname = null;  
                    });
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe completar el campo de nombre.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                }                
		    },
            removeModule(index, id){ 
                let module = this.modules.find(i => i.id == id);
                if(module && module.exists){
                    axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${id}/module/delete`)
                    .then(response => {                                           
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });                          
                        this.modules.splice(index, 1);
                    })
                    .catch( (err) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al eliminar el módulo del curso',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    }); 
                }                
		    },
            updateInformation(id){
                let error = 0;
                let errorE1 = 0;
                let mod = this.modules.find(i => i.id == id);
                if (mod.name === ''){ error = 1; }
                
                if (error){
                    errorE1 = 1;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe completar la información del campo Nombre.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        }
                    });
                }
                
                if (!errorE1){
                    let moduleData = {
                        moduleId: mod.id,
                        name: mod.name,                     
                    };
                    
                    axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.courseId}/module/update`, moduleData)
                    .then(response => {                                           
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });                          
                    })
                    .catch( (err) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al actualizar el módulo',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    });                     
                }               
            },
        }, 
        setup() {
            const { skin } = useAppConfig();
            
            watch(skin, val => {
                // console.log(val)
            });            
            
            return {
			    skin, 
		    }
        }
    }
</script>

<style lang="scss">
    .moduleStyle {
        box-shadow: rgba(50, 50, 105, 0.05) 0px 2px 5px -0px inset, rgba(50, 50, 105, 0.05) 0px 2px 5px -0px inset; 
        border-radius: 5px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
