import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCourseUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${queryParams.id}/users`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        removeInscription (ctx, { courseId, userId }){
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/course/${courseId}/user/remove-inscription`, {
                        user: userId
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    },
}