<template>	
	<component :is="userData === undefined ? 'div' : 'b-card'">

	<!-- Alert: No item found -->
	<b-alert
	  variant="danger"
	  :show="userData === undefined"
	>
	  <h4 class="alert-heading">
		Error al obtener los datos del curso
	  </h4>
	  <div class="alert-body">
		No se encontró ningún curso con este id. Verificar en la
		<b-link
		  class="alert-link"
		  :to="{ name: 'courses'}"
		>
		  Lista de cursos
		</b-link>
		para buscar otro curso.
	  </div>
	</b-alert>

	<b-tabs
	  v-model="activeTab"
	  v-if="userData"
	  pills
	>
	  <!-- Tab: Information -->
	  <b-tab name="info" v-if="$can('all', 'manage')" >		  
		<template #title>
		  <feather-icon
			icon="InfoIcon"
			size="16"
			class="mr-0 mr-sm-50"
		  />
		  <span class="d-none d-sm-inline">Información</span>
		</template>
		<course-edit-tab-information		  
		  class="mt-2 pt-75" 
		  :user-data="userData"
		  />
	  </b-tab>	  
	  
		<!-- Tab: Inscribed -->
		<b-tab name="inscribed">
			<template #title>
				<feather-icon
					icon="UsersIcon"
					size="16"
					class="mr-0 mr-sm-50"
				/>
				<span class="d-none d-sm-inline">Inscritos</span>
			</template>
			<course-user-list				
				class="mt-2 pt-75"
				:course-id="userData.id"
			/>
		</b-tab>
		
	  <!-- Tab: Module -->
	  <b-tab name="module" v-if="$can('all', 'manage')" >
		<template #title>
		  <feather-icon
			icon="InfoIcon"
			size="16"
			class="mr-0 mr-sm-50"
		  />
		  <span class="d-none d-sm-inline">Módulos</span>
		</template>
		<module-edit-tab-information
		  class="mt-2 pt-75" 
		  :course-id="userData.id"
		  :user-data="userData"
		  />
	  </b-tab>	
	  
	   <!-- Tab: Survey -->
	  <b-tab name="survey">
		<template #title>
		  <feather-icon
			icon="InfoIcon"
			size="16"
			class="mr-0 mr-sm-50"
		  />
		  <span class="d-none d-sm-inline">Encuestas</span>
		</template>
		<course-survey		  
		  class="mt-2 pt-75" 
		  :course-id="userData.id"
		  />
	  </b-tab>		  
	  
	</b-tabs>
  </component>

</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import courseStoreModule from '../courseStoreModule';
import CourseEditTabInformation from './CourseEditTabInformation.vue';
import ModuleEditTabInformation from '../courses-tabs/ModuleEditTabInformation.vue';
import CourseUserList from "../courses-tabs/CourseUserList";
import CourseSurvey from "../courses-tabs/CourseSurvey";

export default {
	components: {
		BTab,
		BTabs,
		BCard,
		BAlert,
		BLink,

    	CourseUserList,
		CourseEditTabInformation,
		ModuleEditTabInformation,
		CourseSurvey,
CourseSurvey,
	},

    data (){
	    return {
            //activeTab: 0
        }
    },
    computed: {
        activeTab: {
            set(val) {
                let query = {...router.currentRoute.query};
                query.tab = val;
                router.replace({query: query});
            },
            get() {
                return parseInt(router.currentRoute.query.tab);
            }
        }
    },
	setup() {

		const userData = ref(null);

		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		});

		store.dispatch('app-user/fetchCourse', { id: router.currentRoute.params.id })
		.then(response => {
			if (response.data === 'error') {
				userData.value = undefined
			} else {
				userData.value = {
					changePhoto: 0,
					changeImg: 0,
					...response.data
				}
			}
		})
		.catch(error => {
			if (error.response.status === 404) {
				userData.value = undefined
			}
		});
		
		return {
			userData,
		}
	},
}
</script>