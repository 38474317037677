import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useCourseUsersList(id) {
    // Use toast
    const toast = useToast();
    
    const refUserListTable = ref(null);
    
    // Table Handlers
    let tableColumns = [
        { key: 'access.fullName', sortable: true, label: 'Usuario' },
        { key: 'documentNumber', sortable: true, label: 'Número de documento' },
        { key: 'access.email', sortable: true, label: 'Correo' },
        { key: 'access.phone', sortable: true, label: 'Teléfono' },        
    ];
    
    if (JSON.parse(localStorage.getItem('userData')).role == 'admin'){
		tableColumns.splice(4,0,{ key: 'customer.name', sortable: true, label: 'Cliente Asociado' });
        tableColumns.splice(5,0,{ key: 'actions', label: 'Opciones' });
    }
    
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    
    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });
    
    const refetchData = () => {
        refUserListTable.value.refresh()
    };
    
    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    });
    
    const fetchCourseUsers = (ctx, callback) => {
        store
            .dispatch('app-course/fetchCourseUsers', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                id,
                role: JSON.parse(localStorage.getItem('userData')).role,
            	customer: JSON.parse(localStorage.getItem('userData')).customer,
            })
            .then(response => {
                const { total, users } = response.data;
                callback(users);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista del banco de preguntas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            })
    };
    
    return {
        fetchCourseUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        
        refetchData,
    }
}