<template>

    <div>
        <b-row>
            <b-col
                cols="12"
                class="mb-1"
            >
                <h5 class="mb-0">
                    Inscritos del Curso
                </h5>
                <small class="text-muted">
                    Lista de usuarios inscritos
                </small>
                <hr>
            </b-col>
            <b-col 
                cols="12" 
                class="mb-2"
            >
                <b-row>
                    <!-- Per Page -->
                    <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>Mostrar</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                    />
                    <label>registros</label>
                </b-col>
                
                <!-- Search -->
                <b-col
                    cols="12"
                    md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Buscar ..."
                        />
                        <!-- :to="{ name: 'questions-store'}"> -->
                        <!--
                        <b-button
                            variant="primary">
                            <span class="text-nowrap">Agregar Inscripción</span>
                        </b-button>
                        -->
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-modal.modal-no-backdrop
                            variant="primary"
                            v-if="$can('all', 'manage')"
                        >
                            <span class="text-nowrap">Agregar Inscripción</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>                        
            </b-col>
            <b-col> 
                <b-table
                    ref="refUserListTable"
                    class="position-relative"
                    :items="fetchCourseUsers"
                    responsive
                    :fields="tableColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    empty-text="No matching records found"
                    :sort-desc.sync="isSortDirDesc">

                    <!-- Column: User -->
                    <template #cell(access.fullName)="data">
                        <b-media vertical-align="center" v-if="$can('all', 'manage')">
                            <template #aside>
                                <b-avatar
                                    size="32"
                                    :src="avatarSrc(data.item.access.photo)"
                                    :text="avatarText(data.item.access.fullName)"
                                    variant="light-success"
                                    :to="{ name: 'users-edit', params: { id: data.item.id } }"
                                />
                            </template>
                            <b-link
                                :to="{ name: 'users-edit', params: { id: data.item.id } }"
                                class="font-weight-bold d-block text-nowrap">
                                {{ data.item.access.fullName }}
                            </b-link>
                        </b-media>
                        <b-media vertical-align="center" v-else>
                            <template #aside>
                                <b-avatar
                                    size="32"
                                    :src="avatarSrc(data.item.access.photo)"
                                    :text="avatarText(data.item.access.fullName)"
                                    variant="light-success"
                                />
                            </template>
                            <b-link                                
                                class="font-weight-bold d-block text-nowrap">
                                {{ data.item.access.fullName }}
                            </b-link>
                        </b-media>
                    </template>

                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL">

                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>

                            <!-- @click="restartAttempts(data.item.id)" -->
                            <b-dropdown-item @click="showModalRestartAttempts(data.item.id)">
                                <feather-icon icon="RefreshCcwIcon" />
                                <span class="align-middle ml-50">Reiniciar intentos</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="removeInscription(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Eliminar inscripción</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="generateCertificate(data.item.id)">
                                <feather-icon icon="AwardIcon" />
                                <span class="align-middle ml-50">Regenerar certificado</span>
                            </b-dropdown-item>

                        </b-dropdown>
                    </template>

                </b-table>
                
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end">

                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalUsers"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item">

                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </b-col>

            <!-- modal add inscription -->
            <b-modal
                ref="modal"
                id="modal-no-backdrop"
                hide-backdrop
                no-close-on-backdrop
                content-class="shadow"
                title="AGREGAR INSCRIPCIÓN"
            >
                <b-form>
                    <b-form-group
                        class="mb-0"
                        label="Seleccione un usuario"
                        label-for="vue-select"
                    >
                        <v-select
                            id="vue-select"
                            v-model="data.inscription"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="fullName"
                            :options="users"
                        />
                    </b-form-group>
                </b-form>
                <template #modal-footer>
                    <b-button
                        variant="primary"
                        @click="addInscription"
                        class="float-right">
                        Agregar
                    </b-button>
                </template>
            </b-modal>

            <!-- restart attempts -->
            <b-modal
                size="lg"
                id="modal-select2"
                ref="modal-select2"
                title="REINICIAR 3 INTENTOS"
                hide-backdrop
                no-close-on-backdrop
            >
                <b-form>
                    <b-form-group
                        label="Seleccione un módulo"
                        label-for="vue-select2"
                    >
                        <v-select
                            id="vue-select2"
                            v-model="module"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="modules"
                            trim
                        />
                    </b-form-group>
                    <b-form-group
                        label="Seleccione un examen"
                        label-for="vue-select3"
                    >
                        <v-select
                            id="vue-select3"
                            v-model="exam"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="exams"
                            trim
                        />
                    </b-form-group>
                </b-form>
                <template #modal-footer="{ cancel }">
                    <b-button variant="primary" @click="restartAttempts">
                        Reiniciar
                    </b-button>
                    <b-button variant="outline-secondary" @click="cancel()">
                        Cancelar
                    </b-button>
                </template>
            </b-modal>
        </b-row>           
    </div>
</template>

<script>

    import {useToast} from 'vue-toastification/composition';
    import Toast from 'vue-toastification';
    import Vue from 'vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BButton, BCard, BCol, BAvatar, BMedia, BForm, BFormGroup, BModal, VBModal, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import Ripple from 'vue-ripple-directive';
    import { ref, onUnmounted, reactive } from '@vue/composition-api';
    import useCourseUsersList from './useCourseUsersList';
    import courseUserStoreModule from '../courseUserStoreModule';
    import {avatarText} from '@core/utils/filter';
    import axios from 'axios';

    Vue.use(Toast);

    export default {
        components: {
            BModal,
            BAvatar,
            BMedia,
            BCard,
            BRow,
            BCol,
            BFormGroup,
            BForm,
            BFormInput,
            BButton,
            BTable,
            BLink,
            BDropdown,
            BDropdownItem,
            BPagination,
            vSelect,
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            courseId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                userId: null,
                module: null,
                exam: null,
                inscription: null,
                users: [],
                modules: [],
                exams: []
            }
        },
        watch: {
            module (newValue) {
                this.exam = null;
                this.exams = [];

                if (newValue){
                    this.getExams();
                }
            }
        },
        mounted() {
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.$route.params.id}/users/select`)
                .then(response => {
                    let users = [];
                    response.data.map(item => {
                        users.push({
                            id: item.id,
                            fullName: item.access.fullName
                        });
                    });

                    this.users = users;
                    this.getModules();
                });
        },
        methods: {
            avatarSrc (img){
                if (img){
                    if (img.includes('http')){
                        return img;
                    } else {
                        if (process.env.VUE_APP_ENVIROMENT == 'development'){
                            return require(`@/assets/images/modules/users/${img}`);
                        } else {
                            return `${process.env.VUE_APP_FILES}/modules/users/${img}`;
                        }
                    }
                } else {
                    return null;
                }
            },
            async getModules (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/modules/select-filtered?courses=${this.courseId}`);
                this.modules = response.data;
            },
            showModalRestartAttempts (userId){
                this.userId = userId;
                this.$refs['modal-select2'].show();
            },
            async getExams(){
                let moduleId = this.module ? this.module.id : '';
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/exams/select-filtered?modules=${moduleId}`);
                this.exams = response.data;
            },
            async generateCertificate (userId){

                let data = {
                    course: this.$route.params.id,
                    user: userId,
                    _token: '4cfVgp8QLUavPKD1KSVnOEm6J3GHxlI3Yfeu5FzL'
                };

                let response = await axios.post(`${process.env.VUE_APP_WEB}/api/restart/certificate`, data);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: response.data.icon,
                        variant: response.data.variant
                    }
                });

            },

            restartAttempts () {

                if (this.exam){

                    axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/exam/${this.exam.id}/user/restart-attempts`, {
                            user: this.userId,
                            course: this.$route.params.id
                        })
                        .then(response => {
                            this.$refs['modal-select2'].hide();
                            this.module = null;
                            this.exam = null;
                            this.userId = null;
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                }
                            });
                        });

                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe seleccionar un examen para reiniciar los 3 intentos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                }

            }
        },
        setup(props) {

            const modal = ref();

            const data = reactive({
                inscription: null,
            });

            // Use toast
            const toast = useToast();

            const USER_APP_STORE_MODULE_NAME = 'app-course';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseUserStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

            const {
                fetchCourseUsers,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData
            } = useCourseUsersList(props.courseId);

            const removeInscription = (userId) => {
                store.dispatch('app-course/removeInscription', { courseId: props.courseId, userId })
                    .then( (response) => {
                        refetchData();
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });
                    })
                    .catch( () => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al eliminar la inscripción',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        });
                    });
            };

            const hideModal = () => {
                modal.value.hide();
            };

            const addInscription = () => {
                if (data.inscription){

                    axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/${props.courseId}/user/add-inscription`, {
                            user: data.inscription.id
                        })
                        .then(response => {
                            hideModal('add-inscription-modal');
                            data.inscription = null;
                            refetchData();
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            });
                        });

                } else {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe seleccionar a un usuario para procesar la inscripción.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                }
            };

            return {
                data,
                modal,
                fetchCourseUsers,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData,
                // Filter
                avatarText,
                removeInscription,
                addInscription,
                hideModal
            }
        }
    }

</script>

<style lang="scss" scoped>

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>