<template>
	<div>
		<!-- BODY -->
		<validation-observer
			#default="{ handleSubmit }"
			ref="refFormObserver"
		>
			<!-- Form -->
			<b-form
				@submit.prevent="handleSubmit(updateInformationCourse)"
			>
				<b-row>
					<b-col
						cols="12"
						class="mb-2"
					>
						<h5 class="mb-0">
							Detalles del Curso
						</h5>
						<small class="text-muted">
							Actualice los detalles del curso
						</small>
						<hr>
					</b-col>
					
					<b-col cols="6">
						<b-row>
							
							<b-col md="12">                       
								<b-media class="mb-2" id="avatarCourse">
									<template #aside>
										<b-avatar													
											:src="avatarSrc(userData.photo)"
											:text="avatarText(userData.name)"
											variant="light-success"
											size="90px"
											rounded
										/>
									</template>
									<h4 class="mb-1 mt-1" >
										{{ userData.name }}
									</h4>
									<div class="d-flex flex-wrap">											
										<label class="custom-file-upload">
											<input 
												id="imagen-course"
												type="file"																				
												@change="onFileChangePhoto"
												accept=".jpg,.jpeg,.png"
											/>
											Actualizar
										</label>
										
										<b-button
											@click="removeAvatar"
											variant="outline-secondary"
											class="ml-1 btn-sm"
										>
											<span class="d-none d-sm-inline">Remover</span>
										</b-button>
									</div>
								</b-media>								 
							</b-col>
							
							<!-- Selecccionar Imágen o Video -->
							<b-col md="12">
								<validation-provider
									#default="{ errors }"
									name="tipo de contenido"
									rules="required"
								>
									<b-form-group
										label-for="t-link"
										label="Tipo de contenido introductorio"
									>
										<v-select
											id="t-link"
											v-model="userData.type_intro"
											placeholder="Seleccione una opción"
											:searchable="false"
											:options="['Imagen', 'Video']"
											label="name"
											@input="changeTypeIntro"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
							</b-col>  								
							
							<!-- Selección IMAGEN-->
							<b-col 
								cols="12"
								v-if="userData.type_intro === 'Imagen'"
							> 
								<hr>									
								<b-media class="mb-2 text-center" id="imgIntro">
									
									<!-- Photo preview link -->      										
									<div class="mb-2" v-if="userData.img_intro != null">
										<b-img 
											class="img-fluid" 
											:src="avatarSrc(userData.img_intro)"
											:alt="`Imagen introductiorio del curso ${userData.name}`" 
											width= 450
											rounded 
										/>
									</div>																

									<!-- Photo link file -->											
									<div class="mt-2 d-flex justify-content-center">											
										<label class="custom-file-intro-upload">
											<input 
												id="imagen-intro"
												type="file"													
												:state="userData.stateImg"
												@change="introImageChange"
												accept=".jpg,.jpeg,.png"
												placeholder="Elija un archivo o suéltelo aquí ..."
											/>
											<span class="pr-1">
												{{ userData.type_intro === 'Imagen' && userData.img_intro ? 'Actualizar' : 'Cargar' }}
											</span>
											<feather-icon
												:icon="userData.type_intro === 'Imagen' && userData.img_intro ? 'EditIcon' : 'UploadIcon'"
												class="d-inline"
											/>
										</label>
										<b-button
											@click="removeImg"
											variant="outline-secondary"
											class="ml-1"
										>
											<span class="pr-1">Remover</span>
											<feather-icon
												icon="TrashIcon"
												class="d-inline"
											/>
										</b-button>
									</div>
								</b-media>
							</b-col>           
							
							<!-- Selección VIDEO-->
							<b-col 
								cols="12"        
								v-if="userData.type_intro === 'Video'"      
							>  
								<hr>    
								<!-- rules="url" -->
								<validation-provider
									#default="{ errors }"
									name="enlace del video"
									:rules="!userData.video_intro ? 'required' : null"
								>  
									<b-form-group
										label="Enlace del video"
										label-for="video_intro"
									>
										<!-- placeholder="Ingrese código de Vimeo" -->
										<b-form-input
											id="video_intro"
											type="text"
											v-model="userData.video_intro"
											:state="errors.length > 0 ? false:null"
											trim
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
							</b-col>
							
							<b-col cols="12" v-else >   
							</b-col>
						</b-row>
					</b-col>
					
					<b-col cols="6">
						<b-row> 								
							<!-- Nombre del Curso -->
							<b-col md="12">
								<validation-provider
									#default="{ errors }"
									name="nombre del curso"
									rules="required"
								>
									<b-form-group
										label="Nombre del curso"
										label-for="name"
									>
										<b-form-input
											id="name"
											v-model="userData.name"
											type="text"
											:state="errors.length > 0 ? false:null"
										/>        
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
							</b-col>    								
							<!-- Código -->
							<b-col md="12">
								<b-form-group
									label="Código"
									label-for="code"											
								>
									<b-form-input
										id="code"
										v-model="userData.code"
										type="text"
										disabled
									/>
								</b-form-group>
							</b-col>
																							
							<!-- Descripción del Curso -->          
							<b-col md="12">
								<validation-provider
									#default="{ errors }"
									name="descripción"
									rules="required"
								>
									<b-form-group
										label="Descripción"
										label-for="description"
									>
										<b-form-textarea
											id="description"
											:state="errors.length > 0 ? false:null"
											v-model="userData.description"
											trim
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
							</b-col>
							
							<!-- Expositor -->
							<b-col md="12">
								<validation-provider
									#default="{ errors }"
									name="expositor"
									rules="required"
								>
									<b-form-group
										label="Expositor(es)"
										label-for="speakerId"
									>
										<v-select
											id="speakerId"
											v-model="speakersNew"
											multiple
											label="fullName"
											:options="speakers"
											placeholder="Seleccione una o más opciones"
											:state="errors.length > 0 ? false:null"
											trim
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>  
							</b-col>  
							
							<!-- Cliente -->
							<b-col md="12">                  
								<div class="repeater-form">
									<b-row
										v-for="(item, index) in clients"
										:id="item.id"
										:key="index"                        
										ref="row"
									>
										<!-- Nombre de Cliente -->
										<b-col sm="12" lg="7">
											<validation-provider
												#default="{ errors }"
												name="cliente"
												rules="required"
											>
												<b-form-group
													label="Cliente"
													label-for="customerId"
												>
													<v-select
														id="customerId"
														placeholder="Seleccione una opción"
														v-model="item.name"
														label="name"
														:options="customers"
														:clearable="false"	
														:state="errors.length > 0 ? false:null"
														trim					
													/>
													<small class="text-danger">{{ errors[0] }}</small>
												</b-form-group>
											</validation-provider>
										</b-col>

										<!-- Nota Mínima -->
										<b-col sm="6" lg="3">
											<validation-provider
												#default="{ errors }"
												name="min_score"
												rules="between:0,20|required"
											>
												<b-form-group
													label="Nota Mínima"
													label-for="min_score"
												>
													<b-form-input
														v-model="item.min_score"
														type="number"
														:state="errors.length > 0 ? false:null"
													/>
													<small class="text-danger">{{ errors[0] }}</small>
												</b-form-group>
											</validation-provider>
										</b-col>

										<!-- Remove Button -->
										<b-col
											sm="6" lg="1"
											class="text-center"												
											v-if="index > 0"
										>
											<b-button
												v-ripple.400="'rgba(234, 84, 85, 0.15)'"
												variant="outline-danger"
												class="mt-2"
												@click="removeClient(index, item.id)"
											>
												<feather-icon
													icon="XIcon"                             
												/>
											</b-button>
										</b-col>
									</b-row>
								</div> 
											
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="primary"
									@click="addClient"
								>
									<feather-icon
										icon="PlusIcon"
										class="mr-25"
									/>
									<span> Cliente</span>
								</b-button>
								</b-col>									
						</b-row>
					</b-col>              
				</b-row>        
				<!-- ACTIONS -->
				<div class="d-flex mt-2">			
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						type="submit"
					>
						Guardar cambios
					</b-button>
					<!-- <b-button
						v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						type="button"
						class="mr-2 btn-lg"
						:to="{name: 'courses'}"
						variant="outline-secondary"                
					>
						<span> Cancelar </span>
					</b-button> -->
				</div>
			</b-form>			
		</validation-observer>
	</div>
</template>

<script>
require('@core/assets/fonts/feather/iconfont.css')
import BCardCode from '@core/components/b-card-code'
import { useToast } from 'vue-toastification/composition'
import { avatarText } from '@core/utils/filter';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BAvatar, BSidebar, BForm, BFormTextarea, BFormFile, BFormInvalidFeedback, BInputGroup, BImg,
BButton, BInputGroupAppend, BRow, BCol,BFormGroup, BFormInput, BMedia } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, between, url} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import axios from 'axios'
import courseStoreModule from '../courseStoreModule'
import router from '@/router'

export default {
	components: {
		BAvatar,
		BCardCode,
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		BInputGroupAppend,
		BInputGroup,
		BImg,
		BFormFile,
		BFormInvalidFeedback,
		BButton,
		BMedia,
		BRow,
		BCol,
		vSelect,
		FormWizard,
		TabContent,    
		
		formValidation,
		ToastificationContent,
		ValidationProvider,
		ValidationObserver,
		router
	},
	directives: {
		Ripple,
	},
	props: ['userData'],
	data() {
		return { 
			// Validaciones formulario
			required,
			between,
			
			// Datos de userData			
			img_intro: null,
			speakersNew: [],
			changePhoto: 0,
			changeImg: 0,
			photo: null,
			url_img: null,
			url_photo: null,
			
			// Variables de images
			initPhoto: null,
			initImg: null,
						
			// Lista de todos los clientes y expositores
			customers: [],
			speakers: [],  
			
			// For clients
			deletedClient: [],
			nextTodoId: 1,
			clients: [],
		}
	},	
	beforeMount () {    
		this.setCourseInformation()
		this.getCustomers()
		this.getSpeakers()
	},
	methods: {
		setCourseInformation () {			
			this.speakersNew = this.userData.speakers;
			this.initPhoto = this.userData.photo;
			this.initImg = this.userData.img_intro;
			
			if (this.userData.customers.length) {
				this.userData.customers.map( item => {
					this.clients.push({
						id: item.id,
						name: item.name,
						min_score: item.customerCourse.min_score,
						exists: true
					});
				});  
			}
		},
		
		async getCustomers () {
			const result = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/customers/select`)
			if (result) this.customers = result.data;
		},
		
		async getSpeakers () {
			const result = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/speakers/select`)		
			if (result) this.speakers = result.data;
		},
		
		avatarSrc (img){
			if (img){
				let imgUrl = typeof img == 'object' ? URL.createObjectURL(img) : img
				if (imgUrl.includes('http')){
					return imgUrl;
				} else {
					if (process.env.VUE_APP_ENVIROMENT == 'development') {
						return require(`@/assets/images/modules/courses/${imgUrl}`);
					} else {
						return `${process.env.VUE_APP_FILES}/modules/courses/${imgUrl}`;
					}
				}
			} else {
				return null;
			}
		},
		  
		addClient (){
			this.clients.push({
				id: `new-${this.nextTodoId}`,
				name: '',
				min_score: 0,
				exists: false,
			});
			this.nextTodoId += 1;			
		},
		
		removeClient (index, id){ 
			let client = this.clients.find(i => i.id == id);
			if(client && client.exists){
				this.deletedClient.push(client.id);
			}
			this.clients.splice(index, 1);
		},		
		
		introImageChange (e){
			const fileImg = e.target.files[0]
			this.userData.changeImg = 1
			this.userData.img_intro = fileImg
		},
		
		onFileChangePhoto (e) {			
			const filePhoto = e.target.files[0]
			this.userData.changePhoto = 1
			this.userData.photo =  filePhoto
		},
		
		removeAvatar () {
			this.userData.photo = null
			this.userData.changePhoto = 1;
		},
		
		removeImg () {
			this.userData.type_intro = null
			this.userData.img_intro = null
			this.userData.changeImg = 1
		},
		
		changeTypeIntro (newOption) {
            if (newOption) {
				this.userData.type_intro = newOption
				if (this.userData.type_intro === 'Video') { 
					this.userData.img_intro = null
					this.userData.changeImg = 1	
                } else {
					this.userData.video_intro = null
                } 
            } else { 
				this.userData.type_intro = null
				this.userData.video_intro = null
				this.userData.img_intro = null
				this.userData.changeImg = 1
			}
		},
		
		updateInformationCourse (){			
			let error = 0, errorE1 = 0, errorE2 = 0;
			
			this.clients.map(item => {
				if (item.name === ''){
					error = 1;
				}
			});
			
			let updateExistsCustomers = [];

			this.clients.map(item => {					
				if(typeof item.name == 'object' && item.name != null){
					updateExistsCustomers.push({								
						id: item.name.id,						
						name: item.name.name,
						min_score: item.min_score
					});
				}					
				if(typeof item.name == 'string'){
					updateExistsCustomers.push({								
						id: item.id,						
						name: item.name,
						min_score: item.min_score
					});
				}									  
			});	
			
			let clientArr = updateExistsCustomers.map(function(item){ return item.name });
			let errorDuplicate = clientArr.some(function(item, idx){ 
				return clientArr.indexOf(item) != idx 
			});
			
			if(errorDuplicate){
				errorE1 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'No pueden repetirse los clientes.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if (error){
				errorE1 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Debe completar toda la información.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
          	}
			
			if (!errorE1){			
				if (this.userData.type_intro === 'Imagen' && !this.userData.img_intro) {
					errorE2 = 1;
					
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Debe cargar una imagen introductoria.',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						}
					});
				}
          	}
			
			
			  
			if (!errorE2){
				
				let customersOld = [];
				this.userData.customers.map(item => {
					customersOld.push({id: item.id})
				});
				
				let speakersIdNew = this.speakersNew;
				let speakersArray = [];      
				speakersIdNew.map(item => {
					speakersArray.push({id: item.id});
				});
				
				let speakersIdOld = this.userData.speakers;
				let speakersOldArray = [];      
				speakersIdOld.map(item => {
					speakersOldArray.push({id: item.id});
				});								  
				
				let courseData = {
					deletedClient: this.deletedClient,
					changePhoto: this.userData.changePhoto,
					changeImg: this.userData.changeImg,
					photo: this.userData.photo,
					description: this.userData.description,
					name: this.userData.name,
					type_intro: this.userData.type_intro,
					video_intro: this.userData.video_intro,
					img_intro: this.userData.img_intro,
					initPhoto: this.initPhoto,
					initImg: this.initImg,					
					speakersIdNew: speakersArray,	
					speakersIdOld: speakersOldArray,	
					customersNew: updateExistsCustomers,
					customersOld,
				};
				
				store.dispatch('app-user/updateCourse', { id: this.userData.id, courseData })				
				.then((response) => {		
					 this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: response.data.message,
                              icon: 'CheckIcon',
                              variant: 'success'
                          }
                      });					
                      this.deletedClient = [];
                      this.nextTodoId = 1;			
					  
					router.push({ name: 'courses' });	
				})
				.catch( (err) => {
					let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del curso';
					this.$toast({
						component: ToastificationContent,
						props: {
							title: message,
							icon: 'AlertTriangleIcon',
							variant: 'danger'
						}
					});
				});
			}				
		},
	},
	setup(props, { emit }) {
		
		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})
						
		return {
			avatarText, 
		}    
	}
}
</script>

<style lang="scss">    
	@import '@core/scss/vue/libs/vue-wizard.scss';
	@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>

	#preview {
		text-align: center;
		margin-bottom: 1rem;
	}  
	
	input[type="file"] {
    	display: none;
	}
	
	.custom-file-upload {
		border-color: #7367f0 ;
		background-color: #7367f0;
		border-radius: 0.358rem;
		box-shadow: none;	
		display: inline-block;
		padding: 6.804px 14px;
		margin-bottom: 0px;
		cursor: pointer;
	}	
	
	.custom-file-intro-upload {
		font-size: 14px;
		width: 140px;
		text-align: center;
		border-color: #7367f0 ;
		background-color: #7367f0;
		border-radius: 0.358rem;
		box-shadow: none;	
		display: inline-block;
		padding: 11.004px 18px;
		margin-bottom: 0px;
		cursor: pointer;
	}	
	
</style>