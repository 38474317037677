<template>
    <div>

        <b-form >
            <b-row>
                 <b-col
                    cols="12"
                    class="mb-1"
                >
                    <h5 class="mb-0">
                        Encuesta del Curso
                    </h5>
                    <small class="text-muted">
                        Respuestas de las encuestas
                    </small>
                    <hr>
                </b-col>
                                     
                <!-- Encuestas -->                
                <b-col 
                    cols="12"        
                    v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
                    class="surveyStyle"
                >  
                    <div class="repeater-form mt-2" > 
                        <b-row>
                            <template v-if="surveys.length == 0">
                                <b-col sm="12">
                                    <b-alert
                                        variant="primary"
                                        show
                                    >
                                        <h4 class="alert-heading">
                                            Info
                                        </h4>
                                        <div class="alert-body">
                                            <span>¡Sin encuestas! Los usuarios inscritos todavía no realizan la encuesta.</span>
                                        </div>
                                    </b-alert>
                                </b-col>                                
                            </template>
                            <template v-else>
                                <b-col md="12"
                                    v-for="(item, index) in surveys"
                                    :id="item.id"
                                    :key="index"                             
                                >
                                    <b-card-actions                                    
                                        :title= "`${index + 1 + '. ENCUESTADO(A): ' + item.user}`"                                    
                                        action-collapse
                                        class="cardStyle"                                        
                                    >                                
                                        <b-card-body>                                    
                                            <b-card-title>
                                                Información
                                            </b-card-title>
                                            <b-card-text>
                                                <li>Calidad de enseñanza: <strong>{{item.r1}}</strong></li>
                                                <li>Ayudas audiovisuales mostradas en el curso: <strong>{{item.r2}}</strong></li>
                                                <li>Aplicabilidad de los temas: <strong>{{item.r3}}</strong></li>
                                                <li>¿El curso cubrió con sus expectativas?: <strong>{{item.r4}}</strong></li>
                                                <li>Comentarios y/o Observaciones: <strong>{{item.r5}}</strong></li>
                                                <li>Fecha realizada: <strong>{{item.date}}</strong></li>
                                            </b-card-text>                                        
                                        </b-card-body>                                    
                                    </b-card-actions>
                                </b-col>
                            </template>                            
                        </b-row>                       
                    </div> 
                </b-col>	
            </b-row>            
        </b-form>    
    </div>
</template>

<script>        
    import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
    import { BAlert, BForm, BRow, BCol, BCardText,BCardBody,BCardTitle} from 'bootstrap-vue';
    import Ripple from 'vue-ripple-directive'
    import { watch } from '@vue/composition-api';
    import axios from 'axios';
    import useAppConfig from '@core/app-config/useAppConfig';
    
export default {
        components: {    
            BAlert,        
            BCardBody,
            BForm,
            BRow,
            BCol,
			BCardText,
            BCardTitle,
            BCardActions,
        },
        directives: {
		    Ripple,
	    },
        props: {
            courseId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                surveys: [],               
                role: null,
                customer: null,
                infoData: {},
            }
        }, 
        mounted (){
            this.role = JSON.parse(localStorage.getItem('userData')).role;
            this.customer = JSON.parse(localStorage.getItem('userData')).customer;
            this.infoData = {
                role: this.role,
                customer: this.customer
            }
                
            axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.courseId}/surveys`, this.infoData)
                .then(response => {
                    let data = [];
                    response.data.map(item => {
                        data.push({
                            id: item.id,
                            r1: item.r1,
                            r2: item.r2,
                            r3: item.r3,
                            r4: item.r4,
                            r5: item.r5,
                            user: item.users.access.fullName,
                            date: item.createdAt
                        });
                    });
                    this.surveys = data;          
                });
        }, 
        setup() {
            const { skin } = useAppConfig();
            
            watch(skin, val => {
                // console.log(val)
            });            
            
            return {
			    skin, 
		    }
        }
    }
</script>

<style lang="scss">
	.surveyStyle {
		box-shadow: rgba(50, 50, 105, 0.05) 0px 2px 5px -0px inset, rgba(50, 50, 105, 0.05) 0px 2px 5px -0px inset;
        border-radius: 5px
	}
    .cardStyle {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
